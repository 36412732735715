export enum QueryKey {
  Estate = 'estate',
  Estates = 'estates',
  Offers = 'offers',
  Tours = 'tours',
  SavedSearch = 'saved-search',
  PaymentCards = 'payment-cards',
  Notifications = 'notifications',
  User = 'user',
  AccessAccounts = 'access-accounts',
  Banners = 'banners',
  PropertyAddress = 'property-address',
  PropertyDetails = 'property-details',
  PropertyPaymentDetails = 'property-payment-details',
  PropertyDocuments = 'property-documents',
  PropertyAccess = 'property-access',
  PropertyPlan = 'property-plan',
  PropertyAvailability = 'property-availability',
  PropertyPublish = 'property-publish',
  PersonaVerification = 'persona-verification',
  ApplicantScreeningReport = 'applicant-screening-report',
}
